import moment  from "moment";
import * as XLSX from "xlsx";


/**
 * massage the data 
 * @param {*} committedRecords 
 * @returns Array of objects for Excel sheet (column name: Value) 
 */
const CommittedRecordsToExclData = (committedRecords = []) =>
{
    return committedRecords.map(r => (
        {
            'OEM Name': r.oem,
            'Customer': r.companyName,
            'Machine Serial': r.vin,
            'TU Serial': r.tuSerial,
            'Manufacturer Name': r.brand,
            'Model': r.model,
            'Machine Type': r.machineType,
            'Year': r.year,
            'Asset Name': r.assetName,
            'Installation Date': r.installationDate,
            'PO/External Reference': r.manufacturerReferenceId,
            'Installer Notes': r.notes,
            'EngineHours': r.engineHours,
            'CreatedBy': r.createdBy,
            'CreatedOn': moment(r.createdOn).format('YYYY-MM-DD HH:mm:ss'),
            'Ship Date': moment(r.shipDate).format('YYYY-MM-DD')
        }
    ));
}

/**
 * downloads the given data as Excel file
 * @param {*} data Array of object (columnNme : value )
 * @param {*} fileName default records.xlsx 
 */
export const ExportToExcl = (data = [], fileName ='records.xlsx') =>{
    const sheetData = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheetData, 'List');
      XLSX.writeFile(wb,fileName);
}


/**
 *  downloads committed records to Excel file
 * @param {*} records Array
 */
export const ExportCommittedRecordsToExcl = (records = []) => {
    const data = CommittedRecordsToExclData(records);
    const fileName = `${moment().format('YYYYMMDD')}_committed_records.xlsx`;
    ExportToExcl(data, fileName);
}

export default {
    ExportCommittedRecordsToExcl,
    ExportToExcl
}